import React from 'react'

const HomeContent = () => {
	return (
		<div className="content">

			<div className="info-wrapper">

				<div className="mission-statement">
					<h1>Mission Statement</h1>
					<p>
						My Fitness Odyssey's goal is to help those who wish to improve<br />
						their lives through diet and exercise find and meet like-minded<br />
						individuals to share info, tips, and encouragement
					</p>
				</div>

				<h1>Why You Should Join</h1>

				<ul>
					<li>Accountability Tracking</li>
					<li>Community</li>
					<li>Information in Fitness</li>
					<li>And Plenty More!</li>
				</ul>

			</div>

		</div>
	)
}

export default HomeContent